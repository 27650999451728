@font-face {
	font-family: "Raleway";
	src: url("/fonts/Raleway-VF.woff2") format("woff2"),
		url("/fonts/Raleway-VF.woff") format("woff");
	font-style: normal;
}

@font-face {
	font-family: "Moderat Bold";
	src: url("/fonts/Moderat-Condensed-Bold.woff2") format("woff2"),
		url("/fonts/Moderat-Condensed-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Noto Sans";
	src: url("/fonts/Noto-Sans-regular.woff2") format("woff2"),
		url("/fonts/Noto-Sans-regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Dubai";
	src: url("/fonts/Dubai-Regular.woff2") format("woff2"),
		url("/fonts/Dubai-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Noto Sans SC";
	src: url("/fonts/NotoSansSC-Regular.woff2") format("woff2"),
		url("/fonts/NotoSansSC-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Noto Sans TC";
	src: url("/fonts/NotoSansTC-Regular.woff2") format("woff2"),
		url("/fonts/NotoSansTC-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}
